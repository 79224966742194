<script>
export default {
  name: "erjiweituo",
  data() {
    return {
      regname:regname,
      level: app_config.level,
      tasklist: [],
      input_show: false,
      task_list_content: ''
    }
  },
  mounted() {
    this.gettasklist()
  },
  methods: {
    contentTurn(content) {
      return content.split("\n").join("<br/>");
    },
    gettasklist() {
      this.$sa0.post({
        url: this.$api('二级：委托任务列表'),
        data: {},
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.tasklist = response.data.list
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    createTaskList() {
      this.$sa0.post({
        url: this.$api('二级：创建委托任务'),
        data: {
          content: this.task_list_content
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gettasklist()
            this.input_show = false
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    donetasklist(id) {
      this.$sa0.post({
        url: this.$api('二级：确认委托任务'),
        data: {
          id: id
        },
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            this.gettasklist()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    },
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    input_showClick() {
      this.task_list_content = `任务名称：
时间：${this.rTime(new Date())}
备注：`
      this.input_show = true
    },
  }
}
</script>
<template>
  <div>
    <a-modal v-model:visible="input_show" title="下发委托任务" @ok="createTaskList()">
      <div>
        <div>
          <a-space>
            <b>地区：</b>
            <a-select value="2">
              <a-select-option value="2">{{ regname }}</a-select-option>
            </a-select>
          </a-space>
        </div>
        <div>
          <textarea style="height: 400px;border:1px solid #33333340;width: 100%;padding: 10px;margin-top: 10px;" v-model="task_list_content" />
        </div>
      </div>
    </a-modal>
    <div>
      <a-button @click="input_showClick()" type="primary">创建任务</a-button>
    </div>
    <table class="layui-table" lay-size="sm">
      <thead>
      <tr>
        <th>任务内容</th>
        <th>负责地区</th>
        <th>任务状态</th>
        <th>回执</th>
        <th>操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item,key) in tasklist" :key="key">
        <td v-html="contentTurn(item.content)"></td>
        <td>{{ regname }}</td>
        <td>
          <div v-if="Number(item.status) == 1">待处理</div>
          <div v-if="Number(item.status) == 2">已接收</div>
          <div v-if="Number(item.status) == 3">已完成</div>
        </td>
        <td>
          <a target="_blank" v-if="item.callback" :href="item.callback">{{ item.callback }}</a>
        </td>
        <td>
          <a-button @click="donetasklist(item.id)" v-if="Number(item.status) == 2" type="primary">确认任务</a-button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

</style>